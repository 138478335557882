@import "../../../styles/variables";

.wrapper {
  position: relative;
  height: auto;
  background-color: #F5F5F5;
  border-radius: 4px;

  &.isDelayed {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background: #F95858;
      border-radius: 4px 0 0 4px;
    }
  }
}

.firstColumn {
  width: 100%;
  background-color: #EFEFEF;
  border-radius: 4px 0 0 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 12px;
}

.tooltipStatus {
  height: max-content;
}

.status {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0;
  position: relative;
  z-index: 1;
}

.secondColumn {
  width: 100%;
  padding: 8px 13px;
}

.tag {
  display: inline-block;
  width: max-content;
  color: $white;
  border-radius: 2px;
  padding: 1px 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 1px 5px 1px 0;
  &:first-of-type {
    margin-left: 5px;
  }
}

.sprint {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $blue;
  margin-bottom: 4px;
}

.text {
  display: flex;
  margin-bottom: 8px;
  position: relative;
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;

  span {
    color: $gray300;
  }

  &.withoutStatus {
    transform: translateX(-12px);
    text-indent: 18px;
  }
}

.date {
  width: 65px;
  text-align: center;
}

.date,
.difficulty {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.date.delayed:not(:empty) {
  border-bottom: 1px solid $red;
}

.priority {
  width: 28px;
  height: 12px;
  margin: 0;
}

.avatar {
  width: 16px;
  height: 16px;
  font-size: 8px;
}
